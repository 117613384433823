<template>
  <PageWrapper>
    <Empty class="empty_box" :image-size="200" :description="$t('common.field.noDataNow')">
    </Empty>
  </PageWrapper>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/404.scss';
.empty_box {
  height: 80vh;
  padding: 0 40px;

  @include screen-mobile {
    padding: 0 24px;
  }
}
</style>
